const defaultState = {
  activeDate: "",
  timeIntervals: [],
  isLoading: false,
  dateQuickLinks: [],
  hasError: false,
  error: "",
};

export function date(state = defaultState, action) {
  switch (action.type) {
    case "SET_TIME_INTERVALS":
      return {
        ...state,
        ...action.data,
      };
    case "SET_ACTIVE_DATE":
      return {
        ...state,
        activeDate: action.data,
      };
    case "FETCH_TIME_INTERVALS":
      return {
        ...state,
        ...action.data,
      };
    case "SET_DATE_QUICKLINKS":
      return {
        ...state,
        dateQuickLinks: [...action.quicklinks],
      };
    case "CLEAR_DATE_STATE":
      return {
        ...defaultState,
      };
    case "SET_DATE_ERROR":
      return {
        ...defaultState,
        hasError: true,
        error: action.data.error,
      };
    default:
      return state;
  }
}
