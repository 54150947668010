const mainColor = "#42accd"; // Betterbot Blue

export const themes = {
  light: {
    // Main colors
    main: mainColor,
    focusColor: mainColor,
    onlineStatus: "#07D88D",
    windowBackgroundColor: "#fff",
    windowBackgroundImage: "",

    // Content
    contentBackgroundColor: "#fff",

    // Header colors
    headerBackgroundColor: "#F0F0F0",
    headerTextColor: "#0f0538",
    headerBorderBottom: "#ddd",

    // Quicklinks colors
    quicklinksBorderColor: "#0F0538",
    quicklinksBorderHolidayColor: "#D20202",
    quicklinksBorderColorGray: "#F0F0F0",
    quicklinksBackgroundColorGray: "#F0F0F0",
    quicklinksBackBackgroundColor: "#F0F0F0",
    quickLinksCustomOrAnyColor: "#0F0538",
    quickLinksCustomOrAnyBackgroundColor: "#F0F0F0",
    quicklinkBackgroundColor: "#fff",
    quicklinkTextColor: "#0f0538",
    quicklinkTextHoverColor: "#fff",
    quickLinkCustomOrAnyHoverTextColor: "#fff",
    quicklinkHoverBackgroundColor: "#0f0538",
    quicklinkCustomOrAnyHoverBackgroundColor: "#0f0538",
    quicklinkFontWeight: "normal",
    quicklinksDayColor: "#555F78",
    quicklinksDayHolidayColor: "#D20202",
    quicklinkSelectBackgroundColor: "#0F0538",

    // Bot chat bubble colors
    botChatBubbleBackgroundColor: "#F0F0F0",
    botChatBubbleTextColor: "#0f0538",
    typingDotsColor: mainColor,

    // User chat bubble colors
    userChatBubbleBackgroundColor: "#0f0538",
    userChatBubbleTextColor: "#fff",
    selectedFloorplan: "#c3eefc",

    // Other properties
    otherPropertiesAddress: "#999999",

    // Input colors
    inputBackgroundColor: "#fff",
    inputBorderColor: "#0F0538",
    inputButtonColor: "#fff",
    inputButtonBackgroundColor: "#0F0538",
    inputTextColor: "#0f0538",
    inputCharactersLeftColor: "#0F0538",

    // Animations
    transitionSpeed: ".25s",
    typingSpeed: "1.5s",

    // Images
    quicklinkImageBorderColor: "#E3E3E3",
    quicklinkImageBackgroundColor: "#fff",
    quicklinkImageText: "#555F78",

    // Floorplan colors
    nameBackgroundColor: "#fff",
    nameTextColor: "#0F0538",
    nameBorderColor: "#F0F0F0",
    priceBackgroundColor: "#fff",
    priceBorderColor: "#F0F0F0",
    priceTextColor: "#0F0538",
    priceDescriptionTextColor: "#0F0538",
    descriptionTextColor: "#999999",

    // Arrow
    arrowCircleBackgroundColor: "#F0F0F0",
    arrowBackgroundColor: "#0F0538",
    arrowColor: "#0F0538",

    //Sceleton
    sceletonBackgroundColor: "#F4F4F4",
  },
  dark: {
    // Main colors
    main: mainColor,
    focusColor: mainColor,
    onlineStatus: "#adff2f",
    windowBackgroundColor: "#000",
    windowBackgroundImage: "",

    // Content
    contentBackgroundColor: "#1E1E1E",

    // Header colors
    headerBackgroundColor: "#151515",
    headerTextColor: "#fff",
    headerBorderBottom: "#ddd",

    // Quicklinks colors
    quicklinksBorderColor: "#414141",
    quicklinkBackgroundColor: "#151515",
    quickLinksCustomOrAnyColor: "#fff",
    quickLinksCustomOrAnyBackgroundColor: "#414141",
    quicklinkTextColor: "#999999",
    quicklinkTextHoverColor: "#000000",
    quickLinkCustomOrAnyHoverTextColor: "#000000",
    quicklinkHoverBackgroundColor: "#414141",
    quicklinksBackBackgroundColor: "#999999",
    quicklinkCustomOrAnyHoverBackgroundColor: "##999999",
    quicklinkFontWeight: "400",
    quicklinkSelectBackgroundColor: "#414141",
    quicklinkImageBorderColor: "",

    // Bot chat bubble colors
    botChatBubbleBackgroundColor: "#414141",
    botChatBubbleTextColor: "#fff",
    typingDotsColor: mainColor,

    // User chat bubble colors
    userChatBubbleBackgroundColor: "#999999",
    userChatBubbleTextColor: "#000000",
    selectedFloorplan: "#1e1e1e",

    // Input colors
    inputBackgroundColor: "#fff",
    inputBorderColor: mainColor,
    inputButtonColor: mainColor,
    inputTextColor: "#000",
    inputCharactersLeftColor: "#151515",

    // Animations
    transitionSpeed: ".25s",
    typingSpeed: "1.5s",

    // Images
    quicklinkImageBorderColor: "#999999",
    quicklinkImageBackgroundColor: "#151515",
    quicklinkImageText: "",

    // Floorplan colors
    nameBackgroundColor: "#1E1E1E",
    nameTextColor: "#999999",
    nameBorderColor: "#999999",
    priceBackgroundColor: "#414141",
    priceBorderColor: "#414141",
    priceTextColor: "#fff",
    priceDescriptionTextColor: "#999999",
    descriptionTextColor: "#999999",

    // Arrow
    arrowCircleBackgroundColor: "#0F0538",
    arrowBackgroundColor: "#000000",
    arrowColor: "#000000",

    //Sceleton
    sceletonBackgroundColor: "",
  },
};
