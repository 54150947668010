import React from 'react';
import { connect } from 'react-redux';
import styled from "styled-components";

const PrivacyPolicyWrapper = styled.div`
  bottom: 0;
  right: 0;

  @media screen and (max-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    display: none;
  }

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    position: absolute;

    p {
      margin-bottom: 1rem;
      margin-right: 1rem;
    }

    a {
      text-decoration: none !important;
      font-size: 12px;
      color: #42accd;
    }
  }
`;

function PrivacyPolicy({privacyPolicyUrl}) {
  return (
    <>
      {
        privacyPolicyUrl ? (
          <PrivacyPolicyWrapper>
            <p><a href={privacyPolicyUrl} target="_blank" rel="noopener noreferrer">Privacy Policy</a></p>
          </PrivacyPolicyWrapper>
        ) : null
      }
    </>
)
}

const mapStateToProps = (state) => ({
  privacyPolicyUrl: state.settings.privacyPolicyUrl
});


export default connect(mapStateToProps)(PrivacyPolicy);
