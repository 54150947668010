import axios from "axios";

/**
 * Send request to server
 * @param {string}  method                Assigns a method to this URL
 * @param {string}  url                   Sends request to this URL
 * @param {JSON}    data                  Sends this data as body parameters to this request
 */

export async function sendRequest(method, url, data = new FormData()) {
  let request = await axios({
    method,
    url,
    data,
  })
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return Promise.reject(error.response.data);
    });

  return request;
}
