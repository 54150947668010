import styled from "styled-components";

export const AppearFromBottom = styled.div`
  -webkit-animation: messageAppearFromBottom ${(props) =>
          props.theme.themeColors[props.theme.activeTheme].transitionSpeed};
  animation: messageAppearFromBottom ${(props) =>
          props.theme.themeColors[props.theme.activeTheme].transitionSpeed};

  @-webkit-keyframes messageAppearFromBottom {
    0% {
      -webkit-transform: translate3d(0, 30%, 0);
    }

    to {
      -webkit-transform: translateZ(0);
    }
  }

  @keyframes messageAppearFromBottom {
    13% {
      -webkit-transform: translate3d(0, 30%, 0);
      transform: translate3d(0, 30%, 0);
    }

    to {
      -webkit-transform: translateZ(0);
      transform: translateZ(0);
    }
  }
`;

export const MessageWrapper = styled(AppearFromBottom)`
`;

export const BubbleWrapper = styled.div`
  font-size: 16px;

  &.bot-messages {
    .Bubble {
      border-radius: 0 30px 30px 30px;

      &.amenities {
        border-radius: 0 100px 100px 50px
      }

      @media screen and (min-width: ${(props) => props.theme.screenSizes.breakLaptop}) {
        border-radius: 0 50px 50px 50px;
        &.amenities {

        }
      }
    }
  }

`;

export const QuickLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  word-break: keep-all;
  outline: none;
  font-family: Lato;
  font-style: normal;
  box-sizing: border-box;
  border-radius: 50px;
  width: 165px;
  min-height: 40px;
  height: 100%;
  padding: 10px 5px;
  ${({textLength}) => textLength > 40 && `padding: 15px 10px;`}

  font-size: 13px;
  line-height: 16px;
  transition: all ${(props) => props.theme.themeColors[props.theme.activeTheme].transitionSpeed} ease-in-out;

  ${({specials}) => specials && `width: 264px;`};

  @media screen and (min-width: ${(props) => props.theme.screenSizes.breakSmall}) {
    font-size: 15px;
    line-height: 18px;
    padding-right: 8px !important;
    padding-left: 8px !important;
    width: 176px;
    min-height: 40px;
    ${({specials}) => specials && `width: 160px;`};
  }

  @media screen and (min-width: ${(props) => props.theme.screenSizes.breakLaptop}) {
    width: 295px;
    min-height: 60px;
    font-size: 17px;
    line-height: 22px;
    ${({specials}) => specials && `width: 175px;`};
  }
`;

const NavQuickLink = styled(QuickLink)`
  color: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkTextColor};
  justify-content: space-between;
  border: none;
  font-weight: bold;
  padding-right: 15px;
  padding-left: 15px;
`;

export const CustomOrAnyQuickLinkText = styled(QuickLink)`
  background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quickLinksCustomOrAnyBackgroundColor};
  color: ${(props) => props.theme.themeColors[props.theme.activeTheme].quickLinksCustomOrAnyColor};

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkCustomOrAnyHoverBackgroundColor};
    color: ${(props) => props.theme.themeColors[props.theme.activeTheme].quickLinkCustomOrAnyHoverTextColor};
  }
`;

export const NextOrPreviousQuickLinkText = styled(NavQuickLink)`
  background: ${(props) => props?.quicklinkStyle?.bgColorNext != null ? props.quicklinkStyle.bgColorNext : props.theme.themeColors[props.theme.activeTheme].quicklinksBorderColor};
  color: ${(props) => props?.quicklinkStyle?.txtColorNext != null ? props.quicklinkStyle.txtColorNext : "#fff"};

  .arrow,
  .arrow:before,
  .arrow:after {
    background: ${(props) => props?.quicklinkStyle?.txtColorNext != null ? props.quicklinkStyle.txtColorNext : "#fff"};
  }

  &:hover {
    cursor: pointer;
    background: ${(props) => props?.quicklinkStyle?.hoverBgColorNext != null ? props.quicklinkStyle.hoverBgColorNext : props.theme.themeColors[props.theme.activeTheme].quicklinkHoverBackgroundColor};
    color: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkTextHoverColor};
    .arrow,
    .arrow:before,
    .arrow:after {
        background: ${(props) => props?.quicklinkStyle?.hoverColorNext != null ? props.quicklinkStyle.hoverColorNext : ""};
    }
  }
`;

export const SimilarUnitsButton = styled(NavQuickLink)`
    background: ${(props) => props.theme.themeColors[props.theme.activeTheme].main};;
    color: #fff;
    height: auto;
    font-size: 12px;
    text-transform: uppercase;
    font-family: Lato;
    font-weight: 700;
    line-height: 16px;
    width: 265px;
    padding-left: 8px !important;
    padding-right: 8px !important;

    @media screen and (max-width: ${(props) => props.theme.screenSizes.breakXsmall}) {
        font-size: 13px;
        width: 265px;
    }

    @media screen and (max-width: ${(props) => props.theme.screenSizes.breakPhone}) {
      font-size: 14px;
      width: 265px;
    }

    @media screen and (min-width: ${(props) => props.theme.screenSizes.breakSmall}) {
      width: 232px;
    }

    @media screen and (min-width: ${(props) => props.theme.screenSizes.breakLaptop}) {
    font-size: 15px;
    line-height: 18px;
    width: 196px;
    }



    &:hover {
        color: #fff;
        background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkSelectBackgroundColor};
        transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
    }

    &:focus {
        background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkSelectBackgroundColor};
        transition: all 0.25s ease;
        -webkit-transition: all 0.25s ease;
        box-shadow: 0 0 2px 2px ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkSelectBackgroundColor};
    }
`
export const SimilarUnitsQuicklinkText = styled.span`
    width: 100%;
`

export const BackQuickLinkText = styled(NavQuickLink)`
  background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinksBackBackgroundColor};
  text-transform: uppercase;
  padding: 0 20px !important;

  &:hover {
    cursor: pointer;
    background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkHoverBackgroundColor};
    color: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkTextHoverColor};

    .arrow,
    .arrow:before,
    .arrow:after {
      background: #fff;
    }
  }
`;

export const QuickLinkText = styled(QuickLink)`
  font-weight: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkFontWeight};
  color: ${(props) => props?.quicklinkStyle?.txtColor != null ? props.quicklinkStyle.txtColor : props.theme.themeColors[props.theme.activeTheme].quicklinkTextColor};
  border: 2px solid ${(props) => props?.quicklinkStyle?.borderColor != null ? props.quicklinkStyle.borderColor : props.theme.themeColors[props.theme.activeTheme].quicklinksBorderColor};
  background: ${(props) => props?.quicklinkStyle?.bgColor != null ? props.quicklinkStyle.bgColor : props.theme.themeColors[props.theme.activeTheme].quicklinkBackgroundColor};

  &:hover {
    cursor: pointer;
    background: ${(props) => props?.quicklinkStyle?.hoverColor != null ? props.quicklinkStyle.hoverColor : props.theme.themeColors[props.theme.activeTheme].quicklinkHoverBackgroundColor};
    color: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinkTextHoverColor};
  }
`;

export const QuicklinkButton = styled.div`
  box-sizing: border-box;
  outline: none;
  position: relative;

  &:focus-visible {
    outline: none;
    border-radius: 30px;
    box-shadow: 0 0 2px 2px ${(props) => props.theme.themeColors[props.theme.activeTheme].focusColor};
  }
`;

export const Arrow = styled.div`
  background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinksBorderColor};
  height: 1px;
  width: 20px;
  margin: 0;
  position: relative;

  &:before, &:after {
    content: "";
    background: ${(props) => props.theme.themeColors[props.theme.activeTheme].quicklinksBorderColor};
    position: absolute;
    height: 1px;
    width: 10px;
  }
`;

export const LeftArrow = styled(Arrow)`
  &:before {
    left: -2px;
    bottom: -3px;
    transform: rotate(45deg);
  }

  &:after {
    left: -2px;
    top: -3px;
    transform: rotate(-45deg);
  }
`;

export const RightArrow = styled(Arrow)`
  &:before {
    right: -2px;
    bottom: -3px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -2px;
    top: -3px;
    transform: rotate(45deg);
  }
`;

export const SelectRightArrow = styled.div`
  position: relative;
  background: #fff;
  width: 20px;
  height: 1px;
  margin: 0;

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    width: 25px;
  }

  &:before, &:after {
    content: "";
    background: #fff;
    position: absolute;
    height: 1px;
    width: 8px;

    @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
      width: 10px;
    }
  }

  &:before {
    right: -2px;
    bottom: -3px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -2px;
    top: -3px;
    transform: rotate(45deg);
  }
`;

export const CustomStyledArrow = styled.div`
  position: absolute;
  background: ${(props) => props.theme.themeColors[props.theme.activeTheme].arrowCircleBackgroundColor};
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
    box-shadow: 0 0 2px 2px ${(props) => props.theme.themeColors[props.theme.activeTheme].focusColor};
  }

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    width: 47px;
    height: 47px;
  }
`;

const CarouselArrow = styled.div`
  background: ${(props) => props.theme.themeColors[props.theme.activeTheme].arrowBackgroundColor};
  height: 1px;
  width: 13px;
  position: relative;

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    width: 20px;
  }

  &:before, &:after {
    content: "";
    background: ${(props) => props.theme.themeColors[props.theme.activeTheme].arrowColor};
    position: absolute;
    height: 1px;
    width: 6px;
  }
`;

export const LeftCarouselArrow = styled(CarouselArrow)`
  &:before {
    left: -1px;
    bottom: -2px;
    transform: rotate(45deg);
  }

  &:after {
    left: -1px;
    top: -2px;
    transform: rotate(-45deg);
  }
`;

export const RightCarouselArrow = styled(CarouselArrow)`

  &:before {
    right: -1px;
    bottom: -2px;
    transform: rotate(-45deg);
  }

  &:after {
    right: -1px;
    top: -2px;
    transform: rotate(45deg);
  }
`;

export const QuicklinkButtonImage = styled(QuickLinkText)`
  font-size: 12px;
  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    line-height: 1;
    font-size: 15px;
  }
`;

export const ResidentIcon = styled.img`
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin: -5px 0px -5px -4px; 
  
  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin: -5px 0 -5px -12px;
  }
`;