import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { AppearFromBottom, QuicklinkButton } from "global/style";
import { chatActions } from "_actions";
import Input from 'components/Conversation/Utility/Input';

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  height: 32px;
  padding-right: 30px;
  background: #fff;

  @media screen and (min-width: ${(props) => props.theme.screenSizes.breakLaptop}) {
    position: sticky;
  }
`;

const GPTQuicklinkButton = styled(QuicklinkButton)`
     position: absolute;
     left: 20px;
     bottom: 4px;
    `;

const GPTQuicklink = styled.div`

    font-size: 17px;
    font-color: #555F78;
    .img {
      height: 40px !important;
    }

    &:hover {
      opacity: 0.6;
    }
 
    `;

const WithInput = styled.div`
    
    position: absolute;
    left: 0;
    width: 100%;
    margin-bottom: 110px !important;

    @media screen and (min-width: 500px) {
      left: 0px;
      width:90%;
    }

    @media screen and (min-width: ${(props) => props.theme.screenSizes.breakLaptop}) {
      left: -20px;
      width:100%;
    }
  `;
const PoweredBy = styled.img`
  height: 15px;
`;

function Footer({ poweredByLogo, chatGPT, send }) {

  const sendData = {
    value: 'AskMeAnything-AskMeAnythingConversation',
    text: 'Ask me anything'
  };

  const handleClick = (dispatch) => {
    send(sendData, "text");
  };

  const handleClickQuicklink = (e) => {
    if (e.key === "Enter" || e.key === " ") {
      handleClick();
    }
  };
  return (

    <FooterContainer className="FooterContainer">

      <>
        {
          chatGPT.type == 'chatgpt' ?
            (<WithInput className="WithInput"> <Input /></WithInput>) : null
        }
        {
          poweredByLogo ? (
            <PoweredBy src="/images/powered-by-betterbot.png" />
          ) : null
        }
      </>
    </FooterContainer>
  )
}

const mapStateToProps = (state) => ({
  poweredByLogo: state.settings.poweredByLogo,
  isSelected: state.isSelected,
  chatGPT: state.chat.input,
  selectedQuicklinks: state.chat.selectedQuicklinks
});



const actions = {
  send: chatActions.send,
  selectQuicklinks: chatActions.selectQuicklinks,
  clearSelectedQuicklinks: chatActions.clearSelectedQuicklinks,
};

export default connect(mapStateToProps, actions)(Footer);
