import { sendRequest } from "_helpers/request.helper";
import { settingsActions } from "_actions";
import {
  language as getLanguage,
  setEncryptedUserId,
  setRatedUs,
} from "_helpers/general.helper";

async function delay(ms) {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function pullData(arr, result) {
  let data = [];
  arr.forEach((type) => {
    if (result[type]) {
      if (Array.isArray(result[type])) {
        data = [...data, ...result[type].map((q) => ({ ...q, type }))];
      } else {
        data = result[type];
      }
    }
  });

  return data;
}

function clearHistory() {
  return (dispatch) => {
    dispatch({ type: "CLEAR_HISTORY" });
  };
}

function incomingSpecials(result) {
  return (dispatch) => {
    const types = [
      "floorplan-quicklinks",
      "date-quicklinks",
      "gallery-quicklinks",
      "contacts-quicklinks",
      "map-quicklinks",
      "multibot-quicklinks",
      "review-quicklinks",
      "reviewcarousel-quicklinks",
      "virtual-tour",
      "unitmap-quicklinks",
    ];
    let data = [];
    let presentType = "";
    types.forEach((type) => {
      if (result[type]) {
        data = result[type];
        presentType = type.split("-")[0];
      }
    });
    data = pullData(types, result);
    dispatch({
      type: "INCOMING_SPECIALS",
      data: {
        type: presentType,
        list: data,
      },
    });
  };
}

function incomingQuicklinks(result) {
  return (dispatch) => {
    let data = [];
    data = pullData(
      ["redirect-quicklinks", "image-quicklinks", "text-quicklinks"],
      result
    );
    if (result['type'] === "floorplan-quicklinks") {
      dispatch({
        type: "SET_FLOOPRPLANS_LIST",
        data: result,
      })
    } else {
      dispatch({
        type: "INCOMING_QUICKLINKS",
        quicklinks: data,
      });
    }


    let dateData = pullData(["date-text-quicklinks"], result);

    dispatch({
      type: "CLEAR_DATE_STATE",
    });

    dispatch({
      type: "SET_DATE_QUICKLINKS",
      quicklinks: dateData,
    });
  };
}

function selectQuicklinks(quicklink) {
  return (dispatch) => {
    let data = [];

    dispatch({
      type: "TOGGLE_SELECTED_QUICKLINKS",
      quicklink: quicklink,
    });
  };
}
function clearSelectedQuicklinks() {
  return (dispatch) => {
    dispatch({
      type: "CLEAR_SELECTED_QUICKLINKS",
    });
  };
}

function googleAnalytics(result, fromInput) {
  return (dispatch, state) => {
    const personalize = state().settings;
    let sent = result.gaEvent;
    let action = "Conversation";
    let event = pullData(["lead-label"], result);
    let label = event;
    let log = false;
    if (
      (sent &&
        !["Back", "Yes", "No", "Back to Start", "Floorplan"].includes(sent)) ||
      event.length
    ) {
      const text = !sent || sent === "heartbeat" ? "Start Conversation" : sent;
      if (event.length) {
        action = "Lead Generation";
        log = true;
      } else {
        if (!fromInput) {
          label = text;
          log = true;
        }
      }
      if (log) {
        let extras = {};
        let extraVariables =
          personalize.personalization.google_analytics_variables;
        extraVariables = extraVariables ? JSON.parse(extraVariables) : [];

        extraVariables.forEach((el) => {
          extras[el.name] = personalize[el.value];
        });

        let message = { event_action: action, event_label: label };
        if (Object.keys(extras).length) {
          message.extras = extras;
        }
        window.parent.postMessage(message, "*");
        if (process.env.REACT_APP_ENVIRONMENT !== "production") {
          console.log(
            `Google Analytics\n%cAction: ${action} | Event: ${label} | Extras: ${JSON.stringify(
              extras
            )}`,
            "font-weight: bold;"
          );
        }
      }
    }
  };
}

function inputParameters(incoming) {
  return (dispatch) => {
    const visible = !!incoming;
    const defaultSetup = {
      visible,
      type: "",
      name: "",
    };
    let data = defaultSetup;
    if (incoming) {
      data = { ...incoming, visible };
    }

    dispatch({ type: "SETUP_INPUT", data });
  };
}

/**
 * Send message
 * @param {JSON} send Message to send to chat engine
 * @param {string} type Type of message send
 * @returns
 */
function send(send, type = "text", fromInput = false, dataType = "normal") {
  return (dispatch, state) => {
    let message = send;
    if (typeof send === "string") {
      message = {
        text: send,
        value: send,
      };
    }

    // grab parameters from settings reducer
    const { tid, language, urlparams, userId } = state().settings;

    let currentLanguage = language;
    // if language change
    if (message.value === "langauge-change") {
      const newLanguage = currentLanguage === "English" ? "Espanol" : "English";
      currentLanguage = getLanguage(tid, newLanguage);

      /**
       * The useEffect hook in Conversation will automatically send a message if language is changed
       * That's why we can just return setLanguage action here
       */
      dispatch(settingsActions.setLanguage(currentLanguage));
    }

    // Add user message to messages list (quicklink, floorplan selected etc..)
    dispatch({
      type: "SEND_MESSAGE_REQUEST",
      message: {
        ...message,
        type,
        author: "user",
        value: message.value,
        text: message.text,
        visible: message.value !== "heartbeat",
      },
    });
    dispatch(settingsActions.setScroll("smooth"));

    // send the user message to engine
    const url = `${process.env.REACT_APP_API_TRANSFER}${tid}.${process.env.REACT_APP_API_URL}talk${urlparams}`;
    let data = new FormData();
    data.append("tid", tid);
    data.append("userId", userId);
    data.append("message", message.value);
    data.append("language", currentLanguage);
    data.append("driver", "web");
    data.append("gaEvent", message.gaEvent || "");
    data.append("dataType", dataType);

    if (!state().chat.messages.length) {
      data.append("history", true);
      data.append("initial", true);
    }

    if (!state().chat.ratedUs) {
      data.append("checkRatedUs", true);
    }

    if (send.loadingMessage) {
      dispatch({
        type: "INCOMING_TEXT_MESSAGE",
        message: {
          type: "text",
          author: "bot",
          data: send.loadingMessage,
          visible: true,
          avatar: true,
          typing: true,
        },
      });
    }

    sendRequest("POST", url, data).then(
      async (result) => {
        let hash = result?.original?.user_id;
        let ratedUs = result?.original?.rated_us ?? false;
        if (ratedUs) {
          setRatedUs(ratedUs);
          dispatch({ type: "SET_RATED_US", value: ratedUs });
        }

        if (hash !== "undefined") {
          setEncryptedUserId(tid, hash);
        }

        // Mark typing is done
        dispatch({ type: "SEND_MESSAGE_SUCCESS" });

        if (result.history) {
          dispatch({ type: "SET_HISTORY", data: result.history });
        }

        if (result?.disclaimer?.value) {
          dispatch({
            type: "INCOMING_TEXT_MESSAGE",
            message: {
              type: "text",
              author: "bot",
              data: result.disclaimer.value,
              avatar: true,
              visible: true,
            },
          });

          dispatch({ type: "SET_TYPING", value: true });

          dispatch(settingsActions.setScroll("smooth"));
          await delay(1000 * (result.disclaimer.delay ?? 0));
        }

        result.message.forEach((botMessage, i) => {
          if (
            botMessage.text == "Thank you for your feedback." ||
            botMessage.text == "Gracias por sus comentarios."
          ) {
            window.parent.postMessage(
              { message: "minimize bot", ms: "2000" },
              "*"
            );
            window.parent.postMessage(
              { message: "reset bot intent", ms: "2100", intent: "heartbeat" },
              "*"
            );
          }
          if (
            botMessage.text ==
            `I hope I've answered your questions. If you need anything else I'll be right here.` ||
            botMessage.text ==
            `Espero haber respondido a sus preguntas. Si necesitas algo más, estaré aquí.`
          ) {
            window.parent.postMessage(
              { message: "minimize bot", ms: "2000" },
              "*"
            );
            window.parent.postMessage(
              { message: "reset bot intent", ms: "2100", intent: "heartbeat" },
              "*"
            );
          }
          if (
            botMessage.text == "Please provide us with your feedback." ||
            botMessage.text == "Por favor, proporciónenos sus comentarios."
          ) {
            setRatedUs(true);
            dispatch({ type: "SET_RATED_US", value: true });
          }
          // Add the bot text message to the messages list
          dispatch({
            type: "INCOMING_TEXT_MESSAGE",
            message: {
              type: "text",
              author: "bot",
              data: botMessage.text,
              avatar: i === 0,
              visible: botMessage.visible ?? true,
            },
          });
        });

        // Incoming text quicklinks
        dispatch(incomingQuicklinks(result));

        // Incoming specials
        dispatch(incomingSpecials(result));

        // Setup Input info
        dispatch(inputParameters(result.input));

        // Google analytics
        dispatch(googleAnalytics(result, fromInput));

        // Set scroll to true, Conversation component will set it to false after scrolling
        if (result.history) {
          dispatch(settingsActions.setScroll("auto"));
        } else {
          dispatch(settingsActions.setScroll("smooth"));
        }
      },
      (error) => {
        // Add the bot text message to the messages list
        dispatch({
          type: "INCOMING_TEXT_MESSAGE",
          message: {
            type: "text",
            author: "bot",
            data: "I have trouble processing your request, please try again.",
            visible: true,
            avatar: true,
          },
        });

        dispatch({
          type: "INCOMING_QUICKLINKS",
          quicklinks: [
            {
              type: "text-quicklinks",
              text: "Back",
              value: "heartbeat",
            },
          ],
        });

        dispatch(settingsActions.setScroll("auto"));
      }
    );
  };
}

export const chatActions = {
  send,
  selectQuicklinks,
  clearSelectedQuicklinks,
};
