import { themes } from "../_helpers/themes.helper";
import { inIframe } from "../_helpers/general.helper";

const screenSizes = {
  breakXsmall: "320px",
  breakMobile: "375px",
  breakPhone: "450px",
  breakSmall: "500px",
  breakMedium: "720px",
  breakTabletMedium: "768px",
  breakLaptop: "1024px",
  breakXlaptop: "1440px",
  breakLarge: "1200px",
  breakXlarge: "1800px",
};

export function settings(
  state = {
    inIframe: inIframe(),
    screenSizes,
    avatar: "",
    avatarLoaded: false,
    themeColors: themes,
    scroll: false,
    activeTheme: "light", // light or dark assigned from whatever we get from CE
    rentgrata: false,
  },
  action
) {
  switch (action.type) {
    case "SET_DEFAULT_SETTINGS":
      return {
        ...state,
        ...action.data,
      };

    case "SET_PERSONALIZATION_DATA":
      return {
        ...state,
        avatar: action.data.window_bot_avatar,
        companyName: action.data.company_name,
        privacyPolicyUrl: action.data.privacy_policy_url,
        poweredByLogo: action.data.powered_by_logo,
        ratedUs: action.data.rated_us,
        themeColors: {
          ...state.themeColors,
          light: {
            // when we develop themes on chat dashboard, CE should send active theme
            ...state.themeColors.light,
            ...action.data,
            headerBackgroundColor: action.data.window_background_color,
            windowBackgroundImage: action.data.window_background_image,
            headerTextColor: action.data.window_font_color,
          },
        },
        personalization: action.data,
      };

    case "AVATAR_LOADED":
      return {
        ...state,
        avatarLoaded: true,
      };

    case "SET_SCROLL":
      return {
        ...state,
        scroll: action.data,
      };

    case "SET_LANGUAGE":
      return {
        ...state,
        language: action.data,
      };

    case "SET_THEME":
      return {
        ...state,
        activeTheme: action.data,
      };

    case "SET_RENTGRATA":
      return {
        ...state,
        rentgrata: action.data,
      };
    default:
      return state;
  }
}
