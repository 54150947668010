import React from "react";
import styled from "styled-components";

import Status from "./Components/Status.Header";
import Title from "./Components/Title.Header";
import Close from "./Components/Close.Header";

const HeaderContainer = styled.div`
  font-family: Lato;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  z-index: 2;
  transition: all ${(props) => props.theme.themeColors[props.theme.activeTheme].transitionSpeed} ease-in-out;
  height: 60px;
  background-color: ${(props) => props.theme.themeColors[props.theme.activeTheme].headerBackgroundColor};

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    position: sticky;
    border-radius: 20px 20px 0 0;
    height: 80px;
  }
`;

const RightActionButtonsContainer = styled.div`
  display: flex;
  margin-left: auto;
`;

export default function Header() {
  return (
    <HeaderContainer className="HeaderContainer">
      <Status className="Status"/>
      <Title className="Title"/>
      <RightActionButtonsContainer className="RightActionButtonsContainer">
        {/* <Mode /> */}
        <Close/>
      </RightActionButtonsContainer>
    </HeaderContainer>
  );
}
