import { sendRequest } from "_helpers/request.helper";
import { settingsActions } from "_actions";

function sendDate(send, type = "text", fromInput = false, dataType = "normal") {
  return (dispatch, state) => {
    let message = send;
    if (typeof send === "string") {
      message = {
        text: send,
        value: send,
      };
    }

    let isFinalDate = send.value.includes(" ") || send.value.includes("T");
    dispatch({
      type: "SET_ACTIVE_DATE",
      data: isFinalDate ? "" : send.text,
    });

    dispatch({
      type: "FETCH_TIME_INTERVALS",
      data: {
        isLoading: true,
      },
    });

    dispatch(settingsActions.setScroll("smooth"));

    const { tid, language, urlparams, userId } = state().settings;

    let currentLanguage = language;

    const url = `${process.env.REACT_APP_API_TRANSFER}${tid}.${process.env.REACT_APP_API_URL}talk${urlparams}`;
    let data = new FormData();
    data.append("tid", tid);
    data.append("userId", userId);
    data.append("message", message.value);
    data.append("language", currentLanguage);
    data.append("driver", "web");
    data.append("gaEvent", message.gaEvent || "");
    data.append("dataType", dataType);

    if (!state().chat.messages.length) {
      data.append("history", true);
      data.append("initial", true);
    }

    if (!state().chat.ratedUs) {
      data.append("checkRatedUs", true);
    }

    sendRequest("POST", url, data).then((result) => {
      if (result["date-quicklinks"]) {
        dispatch({
          type: "SET_TIME_INTERVALS",
          data: {
            timeIntervals: result["date-quicklinks"],
            isLoading: false,
            hasError: false,
          },
        });
      } else {
        dispatch({
          type: "SET_DATE_ERROR",
          data: {
            error: result.message[0].text,
            hasError: true,
          },
        });

        dispatch({
          type: "SET_DATE_QUICKLINKS",
          quicklinks: result["text-quicklinks"],
        });
        dispatch(settingsActions.setScroll("smooth"));
      }
    });
  };
}

export const dateActions = {
  sendDate,
};
