import React from "react";
import styled from "styled-components";

const StatusIndicator = styled.div`
  margin-left: 20px;
`;

const OnlineStatus = styled.div`
  height: 12px;
  width: 12px;
  background-color: ${(props) => props.theme.themeColors[props.theme.activeTheme].onlineStatus};
  border-radius: 50%;
  border-bottom: 1px solid ${(props) => props.theme.themeColors[props.theme.activeTheme].headerBorderBottom};
  display: inline-block;
`;

function Status() {
  return (
    <StatusIndicator>
      <OnlineStatus id="onlinestatus" />
    </StatusIndicator>
  );
}

export default Status;
