import { sendRequest } from "_helpers/request.helper";

const activeTab = {
  unitList: "unitList",
  virtualTour: "virtualTour",
  unitMap: "unitMap",
};

function setFloorplan(floorplan) {
  return (dispatch) => {
    dispatch({
      type: "SET_FLOORPLAN",
      data: {
        ...floorplan,
      },
    });
  };
}

function setFloorplanUnits(units) {
  return (dispatch) => {
    dispatch({
      type: "SET_FLOORPLAN_UNITS",
      data: units,
    });
  };
}

function setActiveTab(tab) {
  return (dispatch) => {
    dispatch({
      type: "SET_ACTIVE_TAB",
      data: tab,
    });
  };
}

function setUnit(unit) {
  return (dispatch) => {
    dispatch({
      type: "SET_UNIT",
      data: unit,
    });
  };
}

function backFloorplans() {
  return (dispatch) => {
    dispatch({ type: "BACK_FLORPLANS" });
  };
}

function getUnitsData(
  send,
  dataType = "normal"
) {
  return (dispatch, state) => {
    const { tid, language, urlparams, userId } = state().settings;
    let message = send;
    let currentLanguage = language;
    if (send.gaEvent === "Community Map") {
      dispatch({
        type: "MAP_LOADING",
        data: true,
      });
    }

    const url = `${process.env.REACT_APP_API_TRANSFER}${tid}.${process.env.REACT_APP_API_URL}talk${urlparams}`;
    let data = new FormData();
    data.append("tid", tid);
    data.append("userId", userId);
    data.append("message", message.message);
    data.append("language", currentLanguage);
    data.append("driver", "web");
    data.append("gaEvent", message.gaEvent || "");
    data.append("dataType", dataType);

    sendRequest("POST", url, data).then(
      async (result) => {
        if (send.gaEvent === "Community Map") {
          dispatch({
            type: "SET_UNIT_MAP",
            data: result["unitmap-quicklinks"],
          });
          dispatch({
            type: "MAP_LOADING",
            data: false,
          });
        } else {
          dispatch({ type: "SET_QUICKLINKS", data: result["text-quicklinks"] });
        }
      },
      (error) => {
        dispatch({
          type: "MAP_LOADING",
          data: false,
        });
      }
    );
  };
}


export const floorplanActions = {
  setFloorplan,
  setActiveTab,
  activeTab,
  setUnit,
  setFloorplanUnits,
  getUnitsData,
  backFloorplans,
};
