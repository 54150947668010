import React from "react";
import styled from "styled-components";

const StyledContentLoading = styled.div`
  padding-top: 55px;
  @media screen and (min-width: ${props => props.theme.screenSizes.breakMedium}) {
    //width: 70%;
    float: none;
  }
  @media screen and (min-width: ${props => props.theme.screenSizes.breakLaptop}) {
    //width: 50%;
    float: none;
  }
  @media screen and (min-width: ${props => props.theme.screenSizes.breakXlaptop}) {
    //width: 40%;
    float: none;
  }
  @media screen and (min-width: ${props => props.theme.screenSizes.breakXlarge}) {
    //width: 27%;
    float: none;
  }
`;

function Content() {
  return <StyledContentLoading />;
}

export default Content;
