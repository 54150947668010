import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import { chatActions } from "../../../_actions";

const CloseWindowContainer = styled.div`
  margin-left: auto;
  margin-right: 20px;
`;

const FocusButton = styled.button`
  opacity: 0;
  position: absolute;
`;

const CloseButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 25px;
  outline: none;
  display: ${(props) => (props.theme.inIframe ? 'block' : 'none')};
  color: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].headerTextColor || "#000"};
  &:active {
    box-shadow: none;
    border: none;
    opacity: 0.3;
  }
  &:focus {
    box-shadow: 0 0 2px 2px
      ${(props) =>
        props.theme.themeColors[props.theme.activeTheme]
          .focusColor}; /* keyboard-only focus styles */
  }
  @media (min-width: ${(props) => props.theme.screenSizes.breakMedium}) {
    display: ${(props) => (props.theme.inIframe ? 'block' : 'none')};;
  }
`;

function Close({ quicklinksLength, send, ratedUs, input, messages }) {
  const buttonRef = useRef(null);
  const [clickCount, setClickCount] = useState(0);

  const handleClick = () => {
      setClickCount(clickCount + 1)
      
      if (!ratedUs && clickCount < 1 && displayRateUs()) {
        send({value: 'RateUs-DidWeAnswerConversation', text: "Exit"}, 'text')
    } else {
        window.parent.postMessage("minimize please", "*");
        if (input?.type == 'rateus') {
            window.parent.postMessage({message: "reset bot intent", ms: "200", intent: 'heartbeat'}, "*");
        }
        setClickCount(0)
    }
  }

  const displayRateUs = () => {
    let nextToLast = messages?.slice(-2, -1)[0]?.data ?? '';
    let lastElement = messages?.slice(-1)[0]?.data ?? '';
    // If the bot answered a question other than initial intent and if 
    // prospect generated a lead - do not display rate us questions
    return messages.length > 4 && !
        (
            nextToLast.includes('hear back from our leasing team') || 
            lastElement.includes('hear back from our leasing team') || 
            nextToLast.includes('recibir una respuesta de nuestro equipo de arrendamiento') || 
            lastElement.includes('recibir una respuesta de nuestro equipo de arrendamiento') || 
            nextToLast.includes('One of our agents will be back in touch soon') || 
            lastElement.includes('One of our agents will be back in touch soon') || 
            nextToLast.includes('Uno de nuestro agentes se pondrá en contacto pronto') || 
            lastElement.includes('Uno de nuestro agentes se pondrá en contacto pronto') || 
            lastElement.includes('current resident about their experience') || 
            lastElement.includes('un residente actual sobre su experiencia')
        );
  }

  useEffect(() => {
    if (quicklinksLength) {
      buttonRef.current.focus();
    }
  }, [quicklinksLength]);

  return (
    <CloseWindowContainer>
      <FocusButton ref={buttonRef} tabIndex={0} />
      <CloseButton
        onClick={() => {
          window.parent.postMessage("minimize please", "*");
        }}
      >
        <FontAwesomeIcon icon={faTimes} />
      </CloseButton>
    </CloseWindowContainer>
  );
}

const mapStateToProps = (state) => ({
  quicklinksLength: state.chat.quicklinks.length,
  ratedUs: state.chat.ratedUs,
  messages: state.chat.messages,
  input: state.chat.input,
});
const actions = {
  send: chatActions.send,
};

const ConnectedPage = connect(mapStateToProps, actions)(Close);


export default ConnectedPage;
