const defaultState = {
    floorplans: [],
    selectedFloorplan: null,
    floorPlanUnits: [],
    activeUnitTab: "unitList",
    unitMapData: [],
    selectedUnit: "",
    quickLinks: [],
    mapLoading: false,
};



export function floorplan(state = defaultState, action) {
    switch (action.type) {
        case "SET_FLOOPRPLANS_LIST":
            return {
                ...state,
                floorplans: action.data,
            };
        case "SET_FLOORPLAN":
            return {
                ...state,
                selectedFloorplan: action.data,
            };
        case "SET_FLOORPLAN_UNITS":
            return {
                ...state,
                floorPlanUnits: action.data,
            };
        case "REMOVE_FLOORPLAN":
            return {
                ...state,
                selectedFloorplan: null
            };
        case "SET_ACTIVE_TAB":
            return {
                ...state,
                activeUnitTab: action.data
            };
        case "SET_UNIT_MAP":
            return {
                ...state,
                unitMapData: action.data
            };
        case "SET_UNIT":
            return {
                ...state,
                selectedUnit: action.data
            };
        case "BACK_FLORPLANS":
            return {
                ...defaultState,
                floorplans: state.floorplans,
            }
        case "MAP_LOADING":
            return {
                ...state,
                mapLoading: action.data
            }
        case "SET_QUICKLINKS":
            return {
                ...state,
                quickLinks: action.data
            }    
        default:
            return state
    }
}