import {sendRequest} from '_helpers/request.helper';
import {getUserId, getTid} from "../_helpers/general.helper";

const API_URL = process.env.REACT_APP_API_TRANSFER + window.location.host.split('.')[0] + '.' + process.env.REACT_APP_API_URL + 'details';

function get() {
  return (dispatch) => {
    dispatch({type: 'GET_PROPERTY_INFO_REQUEST'});

    sendRequest('POST', API_URL).then(
      (result) => {
        dispatch({type: 'GET_PROPERTY_INFO_SUCCESS', data: result[0]});
        dispatch({type: 'SET_PERSONALIZATION_DATA', data: result[1]});
        const userId = getUserId(getTid(), result[2].reset_conversations_on_refresh);
        dispatch({type: 'SET_DEFAULT_SETTINGS', data: {userId}})
      },
      (error) => {
        dispatch({type: 'GET_PROPERTY_INFO_FAILURE'});
      }
    )
  }
}

export const propertyActions = {
  get,
};
