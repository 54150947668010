import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";

const TitleContainer = styled.div`
  padding-left: 10px;
  font-size: 20px;
  font-weight: 700;
  color: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].headerTextColor};
`;

function Title({ title }) {
  if(title != undefined){
    document.title = title;
  }
  return <TitleContainer>{title}</TitleContainer>;
}

const mapStateToProps = (state) => ({
  title: state.property.property_title,
});
const ConnectedPage = connect(mapStateToProps)(Title);

export default ConnectedPage;
