/**
 * Debounce defined callback fn
 * Adds throttle (or wait) between each fn calls
 * Example: if ms = 1000ms we ensure that fn is called a maximum of once per second
 * @param {func} fn Function to execute
 * @param {int} ms Mininum milliseconds between fn execution
 * @returns fn
 */
export function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export function getTid() {
  return window.location.host.split(".")[0];
}

function parseUrl() {
  var querystring = {};
  if (window.location.search) {
    var parsed = window.location.search.replace('?', '');
    parsed = parsed.split('&');
    if (parsed.length > 0) {
      parsed.forEach(function (element) {
        var data = element.split('=');
        let key = data[0];
        let value = data[1];

        // warning: `user_id` is case sensitive
        querystring[key.toLowerCase()] = key.toLowerCase() == 'user_id' ? value : value.toLowerCase();
      });
    }
  }
  return querystring;
}

/**
 * Get current or generate new user id if one does not exist
 * @param {string} tid
 */
export function getUserId(tid, shouldReset = false) {
  let id = '';
  if (!navigator.cookieEnabled) {
    id = parseUrl()['uid'];
  } else {
    const params = parseUrl();
    if (params['user_id'] && params['user_id'] != 'undefined') {
      id = params['user_id'];
      localStorage.setItem('betterbot_' + tid, id);
    } else if (
      !shouldReset &&
      (localStorage.getItem('betterbot_' + tid) && localStorage.getItem('betterbot_' + tid) != 'undefined')
    ) {
      id = localStorage.getItem('betterbot_' + tid);
    }
    else {
        id = Math.floor(Math.random() * 1000000000);
        localStorage.setItem('betterbot_' + tid, id);
    }
  }
  return id;
}

/**
 * Set betterbot_rated into local storage
 * @param {boolean} value
 */
export function setRatedUs(value) {
    localStorage.setItem('betterbot_rated_us', value);
}
/**
 * Set user_id encrypted hash into local storage
 * @param {string} tid
 * @param {string} hash
 */
export function setEncryptedUserId(tid, hash) {
  let user_id = getUserId(tid);
  let data = hash.split(':'); // `{is_user_id_newly_generated}:{encrypted_user_id}`

  if ((user_id !== 'undefined' && user_id.length < 15) || data[0] == 1) {
    if (navigator.cookieEnabled) {
      localStorage.setItem('betterbot_' + tid, data[1]);
    }
  }
}

/**
 * Format string to title case
 * @param {string} str 
 * @returns 
 */
export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) { return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase(); });
}

/**
 * Get or change the current language
 * @param {string} tid Tenant ID
 * @param {string} to New language
 * @returns
 */
export function language(tid, to = '') {
  // If new language is not set up return language from storage
  if (!to && navigator.cookieEnabled) {
    const currentLanguage = localStorage.getItem('betterbot_language_' + tid);
    if (!currentLanguage) {
      // if storage is empty, then save default language to it
      return language(tid, 'English');
    }
    return currentLanguage;
  }

  // Store the new value to storage if available
  if (to && navigator.cookieEnabled) {
    localStorage.setItem('betterbot_language_' + tid, to);
  }

  // Return new or default language
  return to || 'English';
}

/**
 * Get or change current theme
 * @param {string} to New theme
 * @returns Current theme (after change if applicable)
 */
export function theme(to = '') {
  // If new theme is not set up return language from storage
  if (!to && navigator.cookieEnabled) {
    const currentTheme = localStorage.getItem('betterbot_theme');
    if (!currentTheme) {
      // if storage is empty, then save default theme to it
      return theme('light');
    }
    return currentTheme;
  }

  // Store the new value to storage if available
  if (to && navigator.cookieEnabled) {
    localStorage.setItem('betterbot_theme', to);
  }

  // Return new or default theme
  return to || 'light';
}

/**
 * Returns whether the app is open in an iframe
 * @returns Bool
 */
export function inIframe() {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
}

/**
 * Translates an english string to the current active language
 *
 * @param {string} string English string to be translated
 * @param {string} language Language in which the string needs to be translated
 */
export function translate(string, language) {
  const library = [
    {
      english: 'most recent',
      espanol: 'más reciente',
    },
    {
      english: 'Select',
      espanol: 'Seleccione'
    },
    {
      english: 'View Similar Floorplans Nearby',
      espanol: 'Ver planos de planta similares en los alrededores'
    },
    {
      english: 'Units available',
      espanol: 'Unidades disponibles'
    },
    {
      english: 'Unit available',
      espanol: 'Unidad disponible'
    },
    {
      english: 'Available on: ',
      espanol: 'Disponible en: '
    },
    {
      english: 'No units available',
      espanol: 'No hay unidades disponibles'
    },
    {
      english: 'Units: ',
      espanol: 'Unidades: '
    },
    {
      english: 'Reviews',
      espanol: 'Comentarios'
    },
    {
      english: 'Units',
      espanol: 'Unidades'
    },
    {
      english: "Virtual Tours",
      espanol: "Visitas virtuales",
    },
    {
      english: "Map",
      espanol: "Mapa"
    },
    {
      english: "Unit Map",
      espanol: "Mapa de la unidad"
    },
    {
      english: "Apply Now",
      espanol: "Aplica ya"
    },
    {
      english: "SPECIALS",
      espanol: "OFERTAS"
    },
    {
      english: "AMENITIES",
      espanol: "COMODIDADES"
    },
    {
      english: "View Unit",
      espanol: "Ver unidad"
    },
    {
      english: "View Units",
      espanol: "Ver unidades"
    },
    {
      english: "You are going to leave the chat",
      espanol: "Vas a salir del chat"
    },
    {
      english: "You are going to be redirected to a page outside of this conversation.",
      espanol: "Serás redirigido a una página fuera de esta conversación."
    },
    {
      english: "No Available Units At The Moment",
      espanol: "No hay unidades disponibles en este momento"
    },
    {
      english: "Get Notified",
      espanol: "Ser notificado"
    },
    {
      english: "Starting at:",
      espanol: "A partir de:"
    }
  ];

  const check = library.filter((el) => el.english === string);

  if (check) {
    return check[0][language.toLowerCase()];
  }

  return string;
}

export function isInDevelopmentMode() {
  return process.env.NODE_ENV && process.env.NODE_ENV === 'development';
}
