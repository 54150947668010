let ratedUs = false;
try {
  ratedUs = localStorage.getItem("betterbot_rated_us") === "true";
} catch (e) {}

const defaultState = {
  typing: true,
  ratedUs: ratedUs,
  messages: [],
  interactions: 0,
  quicklinks: [],
  selectedQuicklinks: [],
  specials: [],
  input: {
    visible: false,
    type: "",
    name: "",
    placeholder: "",
  },
};

export function chat(state = defaultState, action) {
  switch (action.type) {
    case "SET_TYPING":
      return {
        ...state,
        typing: action.value,
      };
    case "SEND_MESSAGE_REQUEST": {
      const allMessages = action.message.text
        ? [...state.messages, action.message]
        : state.messages;
      return {
        ...state,
        typing: true,
        messages: allMessages,
        quicklinks: [],
        specials: [],
        input: {
          visible: false,
          type: "",
          name: "",
          placeholder: "",
        },
      };
    }
    case "SEND_MESSAGE_SUCCESS": {
      return {
        ...state,
        interactions: state.interactions + 1,
        typing: false,
      };
    }
    case "SET_RATED_US": {
      return {
        ...state,
        ratedUs: action.value,
      };
    }
    case "SET_HISTORY": {
      let filtered = action.data.filter(function (item, pos, arr) {
        return pos === 0 || item.data !== arr[pos - 1].data;
      });
      if (filtered.length < 2) {
        filtered = [];
      }
      if (filtered.length) {
        filtered.push({ type: "history-separator" });
      }
      return {
        ...state,
        messages: [...filtered],
      };
    }
    case "INCOMING_TEXT_MESSAGE": {
      let checkIfAvatarIsVisible = true;
      if (state.messages.length) {
        checkIfAvatarIsVisible =
          state.messages[state.messages.length - 1].author !== "bot";
      }
      action.message.visible = checkIfAvatarIsVisible;
      return {
        ...state,
        typing: action.message.typing || false,
        messages: [...state.messages, action.message],
      };
    }

    case "INCOMING_QUICKLINKS":
      return {
        ...state,
        quicklinks: action.quicklinks,
      };
    case "TOGGLE_SELECTED_QUICKLINKS":
      let newSelectedQuicklinks = [...state.selectedQuicklinks];
      if (state.selectedQuicklinks.includes(action.quicklink)) {
        newSelectedQuicklinks.splice(
          newSelectedQuicklinks.indexOf(action.quicklink),
          1
        );
      } else {
        newSelectedQuicklinks.push(action.quicklink);
      }

      return {
        ...state,
        selectedQuicklinks: newSelectedQuicklinks,
      };
    case "CLEAR_SELECTED_QUICKLINKS":
      return {
        ...state,
        selectedQuicklinks: [],
      };
    case "INCOMING_SPECIALS":
      return {
        ...state,
        specials: {
          type: action.data.type,
          list: action.data.list,
        },
      };
    case "SEND_MESSAGE_FAILURE":
      return {
        ...state,
        typing: false,
      };

    case "SETUP_INPUT":
      return {
        ...state,
        input: action.data,
      };

    case "CLEAR_HISTORY":
      return {
        ...state,
        messages: [],
      };
    default:
      return state;
  }
}
