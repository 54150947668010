import React, { Suspense, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import Header from '../Header/Header';
import Loading from '../Loading/Content.Loading';
import { useWindowSize } from '../Conversation/Utility/hooks';
import Footer from '../Footer/Footer';
import PrivacyPolicy from "../Footer/PrivacyPolicy";

// Show suspense while this
const Conversation = React.lazy(() => import('../Conversation/Conversation'));

// Global style from styled components that is applied everywhere
const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${(props) =>
    props.data.themeColors[props.data.activeTheme].windowBackgroundColor};

    transition: all ${(props) =>
    props.data.themeColors[props.data.activeTheme].transitionSpeed} ease-in-out;

    @media screen and (min-width: ${(props) => props.data.screenSizes.breakLaptop}) {
        background: ${(props) => props.data.themeColors[props.data.activeTheme].windowBackgroundImage != null
    ? 'url(\'' + props.data.themeColors[props.data.activeTheme].windowBackgroundImage + '\')'
    : props.data.themeColors[props.data.activeTheme].windowBackgroundColor
  };
    }

    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }

  *:not(.UnitList):not(.ListFloorplansWraper)::-webkit-scrollbar { display: none; }

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }

`;

/**
 * Complete App Wrapper
 * holds props that append custom css written in "your brand -> bot window" section
 *  */
const AppWrapper = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  height: 100%;
  width: 100%;
  ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].window_custom_css || ''};

  @media screen and (min-height: 100px) and (min-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    padding-top: 48px;
  }

  @media screen and (min-height: 500px) and (min-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    padding-top: 40px;
  }

  @media screen and (min-height: 900px) and (min-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    padding-top: 96px;
  }
`;

const ContentWrapper = styled.div`
  background: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].contentBackgroundColor};

  @media screen and (max-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    box-shadow: 1px 1px 31px 0 rgba(0,0,0,0.15);
    -webkit-box-shadow: 1px 1px 31px 0 rgba(0,0,0,0.15);
    -moz-box-shadow: 1px 1px 31px 0 rgba(0,0,0,0.15);
    width: 1300px;
    border-radius: 20px;
    position: relative;
    max-height: ${({ maxHeight }) => maxHeight}px;
    overflow: hidden;
  }
`;

const Content = styled.div`
  @media screen and (max-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    display: flex;
    justify-content: center;
    position: relative;
    height: 100%;
    width: 100%;
  }

  @media screen and (min-width: ${(prop) =>
    prop.theme.screenSizes.breakLaptop}) {
    overflow-y: scroll;
    height: 100%;
  }
  position: relative;
`;


function App({ screenSizes, themeColors, activeTheme, inIframe, property }) {
  const { width, height } = useWindowSize();
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    if (width > 1000) {
      if (height >= 900) {
        setMaxHeight(height - 200);
      } else if (height >= 500 && height < 900) {
        setMaxHeight(height - 85);
      } else if (height < 500) {
        setMaxHeight(height - 96);
      }
    }
  }, [height, width]);


  if (
    property &&
    property.property_skip_betterbot_indexing == '1' &&
    document.querySelectorAll('meta[name="robots"][content="noindex"]').length == 0
  ) {
    const meta = document.createElement('meta');
    meta.setAttribute('name', 'robots');
    meta.setAttribute('content', 'noindex');

    document.getElementsByTagName('head')[0].appendChild(meta);
  }

  return (
    <Router>
      <GlobalStyle data={{ themeColors, activeTheme, screenSizes }} />
      {/* Theme Provider adds the ability to access given parameters in child styled components */}
      <ThemeProvider theme={{ screenSizes, themeColors, activeTheme, inIframe }}>
        <AppWrapper className="AppWrapper">
          <ContentWrapper className="ContentWrapper" maxHeight={maxHeight}>
            <Content className="Content">
              <Header />
              {/* Suspense renders given "fallback" until child components render */}
              <Suspense fallback={<Loading />}>
                <Switch>
                  {/* Call this route when you want to directly start a different intent e.g. url.com/ScheduleTour */}
                  <Route exact path="/:intent?" component={Conversation} />
                  <Route path="*">
                    <div>Error 404 URL not found.</div>
                  </Route>
                </Switch>
              </Suspense>
              <Footer />
            </Content>
          </ContentWrapper>
        </AppWrapper>
        <PrivacyPolicy />
      </ThemeProvider>
    </Router>
  );
}

const mapStateToProps = (state) => ({
  screenSizes: state.settings.screenSizes,
  inIframe: state.settings.inIframe,
  themeColors: state.settings.themeColors,
  activeTheme: state.settings.activeTheme,
  property: state.property,
});

const ConnectedPage = connect(mapStateToProps)(App);

export default ConnectedPage;
