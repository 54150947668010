import { language, theme } from "_helpers/general.helper";
import { sendRequest }  from '_helpers/request.helper';

function setDefault(locationParams) {
  return (dispatch) => {
    const tid = window.location.host.split(".")[0];
    const lang = language(tid);
    const activeTheme = theme();
    const inIframe = window.self !== window.top;
    const urlparams = locationParams;
    dispatch({
      type: "SET_DEFAULT_SETTINGS",
      data: {
        tid,
        activeTheme,
        language: lang,
        inIframe,
        urlparams,
      },
    });
  };
}

function avatarLoaded() {
  return (dispatch) => {
    dispatch({type: 'AVATAR_LOADED'});
  }
}

function setScroll(scrollType) {
  return (dispatch) => {
    dispatch({type: 'SET_SCROLL', data: scrollType});
  }
}

function setLanguage(language) {
  return (dispatch) => {
    dispatch({type: 'SET_LANGUAGE', data: language});
  }
}

function setTheme(newTheme) {
  return (dispatch) => {
    theme(newTheme);
    dispatch({type: 'SET_THEME', data: newTheme});
  }
}

function setRentgrata(available) {
  return (dispatch) => {
    dispatch({type: 'SET_RENTGRATA', data: available});
  }
}

function storeMapPins(pins) {
  return (dispatch, state) => {
    console.log('DISPATCH STORE PINS', pins);
    const { tid, urlparams } = state().settings;
    const url = `${process.env.REACT_APP_API_TRANSFER}${tid}.${process.env.REACT_APP_API_URL}store-pins`;

    const data = {
      pins
    };

    sendRequest('POST', url, data).then(
      (success) => {}
    )
  }
}

function logVisitor() {
  return (dispatch, state) => {
    const { tid, inIframe } = state().settings;
    if (!inIframe) {
        const currentdate = new Date();
        const dateOnly = currentdate.getDate() + "_" + (currentdate.getMonth() + 1) + "_" + currentdate.getFullYear();
        
        if (localStorage.getItem(`${tid}_visitor`) !== dateOnly) {
            const referrer = encodeURIComponent(window.document.URL);
            const source = encodeURIComponent(document.referrer);
            const cookie = encodeURIComponent(document.cookie);
            const botLogUrl = process.env.REACT_APP_LOG_URL;
            
            sendRequest('GET', `${botLogUrl}${tid}?referer=${referrer}&source=${source}&cookie=${cookie}`)
            
            localStorage.setItem(`${tid}_visitor`, dateOnly);
        }
    }

  }
}

export const settingsActions = {
  setDefault,
  avatarLoaded,
  setScroll,
  setLanguage,
  setTheme,
  setRentgrata,
  storeMapPins,
  logVisitor,
};
