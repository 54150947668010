import { chat } from "./chat.reducer";
import { settings } from "./settings.reducer";
import { property } from "./property.reducer";
import { date } from "./date.reducer";
import { floorplan } from "./floorplan.reducer"

export default {
  chat,
  settings,
  property,
  date,
  floorplan,
};
