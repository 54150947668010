import React, {useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPaperPlane} from '@fortawesome/free-regular-svg-icons';
import autosize from 'autosize';
import {chatActions, settingsActions} from '_actions';
import {useWindowSize} from "./hooks";


const InputWrapper = styled.div`
  display: flex;
  position: relative;
  padding-left: 20px;
  padding-right: 17px;
  margin-top: 23px;
  flex-direction: column;

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    padding-left: 70px;
    margin-top: 15px;
  }

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    padding-left: 200px;
    margin-top: 20px;
    padding-right: 52px;
  }
`;

const StyledTextarea = styled.textarea`
  color: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].inputTextColor};
  background-color: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].inputBackgroundColor};
  -webkit-transition: box-shadow ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].transitionSpeed} ease-in-out;
  -moz-transition: box-shadow ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].transitionSpeed} ease-in-out;
  transition: box-shadow ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].transitionSpeed} ease-in-out;
  border: 2px solid ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].inputBorderColor};
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  -webkit-appearance: none;
  resize: none;
  overflow: hidden;
  font-family: Lato;
  border-radius: 50px;

  &:focus {
    outline: none;
  }

  padding: 8px 55px 10px 28px;
  font-size: 16px;
  height: 45px;

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    font-size: 17px;
    line-height: 26px;
    padding: 10px 50px 10px 28px;
    height: 49px;
  }

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    padding: 19px 98px 0 28px;
    height: 65px;
  }
`;

const SendButton = styled.button`
  color: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].inputButtonColor};
  background-color: ${(props) =>
    props.theme.themeColors[props.theme.activeTheme].inputButtonBackgroundColor};
  border: none;
  outline: none;
  cursor: pointer;
  position: absolute;
  z-index: 2;
  top: 0;

  right: 17px;
  font-size: 15px;
  border-radius: 0 50px 50px 0;

  width: 43px;
  height: ${({sendButtonHeight}) => sendButtonHeight}px;

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakSmall}) {
    width: 44px;
    height: ${({sendButtonHeight}) => sendButtonHeight}px;
    font-size: 20px;
  }

  @media screen and (min-width: ${(prop) => prop.theme.screenSizes.breakLaptop}) {
    width: 98px;
    height: ${({sendButtonHeight}) => sendButtonHeight}px;
    font-size: 25px;
    right: 35px;
  }
`;

const placeholders = {
  default: {
    English: 'Type something...',
    Espanol: 'Escribe algo...',
  },
  English: {
    phone: '(xxx) xxx-xxxx',
    email: 'e.g. bob.botter@example.com',
    name: 'e.g. Bob Botter',
    income: '$xxxx',
    message: 'Type something...',
    chatgpt: 'Let\'s talk...',    
    unit: '#xxxxx',
  },
  Espanol: {
    phone: '(xxx) xxx-xxxx',
    name: 'e.g. Bob Botter',
    email: 'e.g. bob.botter@example.com',
    income: '$xxxx',
    message: 'Escribe algo...',
    unit: '#xxxxx',
  },
};

const CharLeft = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-right: 40px;
  color: ${(props) => props.theme.themeColors[props.theme.activeTheme].inputCharactersLeftColor};
`;

const maxLength = {
  name: 100,
  income: 8,
  message: 280
}

function Input({input, send, language, setScroll}) {
  const [value, setValue] = useState('');
  const [charCount, setCharCount] = useState(0);
  const inputEl = useRef(null);
  const sendButtonRef = useRef(null);
  const [sendButtonHeight, setSendButtonHeight] = useState(47);
  const {width} = useWindowSize();

  const format = (text) => {
    if (input.type === 'income') {
      let val = text.replace(/[^\d]/g, '');
      return `$${val}`;
    } else if (input.type === 'phone') {
      let x = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    }
    return text;
  };

  const handleChange = (e) => {
    const val = format(e.target.value);

    input.type === "message" && setCharCount(val.length);

    setSendButtonHeight(inputEl.current.offsetHeight);
    setScroll('smooth');
    
    setValue(val);
    autosize(inputEl.current);
  };

  const validateInputValue = () => {
    // If space is provided for name email or message
    if (['name', 'email'].includes(input.type) && value.trim() == '') {
        return false;
    }
    if (input.type === 'income' && ['', '$'].includes(value.trim())) {
      return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (validateInputValue()) {
      send(value, 'text', true, input.type);
      setValue('');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (value.trim()) {
        handleSubmit();
      }
    }
  };

  useEffect(() => {
    if (width) {

      width > 1000 && setSendButtonHeight(65);
      width > 500 && width < 1000 && setSendButtonHeight(49);
      width < 500 && setSendButtonHeight(45);
    }
  }, []);


  const debouncedScrollToBottom = () => {
    setSendButtonHeight(inputEl.current.offsetHeight);
  }
  useEffect(() => {
    window.addEventListener("resize", debouncedScrollToBottom);

    return () => {
      window.removeEventListener("resize", debouncedScrollToBottom);
    };
  }, []);

  return (
      <>
        {input.visible && (
            <InputWrapper className="InputWrapper" visible={true}>
              <StyledTextarea
                  className="StyledTextarea"
                  ref={inputEl}
                  autoFocus={input.type !== "chatgpt"}
                  id="input"
                  placeholder={
                    placeholders[language][input.type] ||
                    placeholders.default[language]
                  }
                  onChange={handleChange}
                  onKeyPress={handleKeyPress}
                  value={value}
                  maxLength={maxLength[input.type] || ''}
                  rows="1"
              />
              <SendButton ref={sendButtonRef} sendButtonHeight={sendButtonHeight} onClick={handleSubmit} disabled={!value} >
                <FontAwesomeIcon icon={faPaperPlane}/>
              </SendButton>
              {input.type === "message" && <CharLeft>
                <span>{charCount}/{maxLength[input.type]}</span>
              </CharLeft>
              }
            </InputWrapper>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  input: state.chat.input,
  language: state.settings.language,
});

const actions = {
  send: chatActions.send,
  setScroll: settingsActions.setScroll,
};

export default connect(mapStateToProps, actions)(Input);